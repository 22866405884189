import {aProductMediaItemBuilder, aProductBuilder} from '@wix/wixstores-graphql-schema/dist/src/builders';
import {generateGuid} from '@wix/wixstores-client-core/dist/src/guid-generator';
import {IProduct} from '../../src/types/app-types';

const demoAssets = [
  '491a0c_61c2fe24c865488e81fb4480f09ed989~mv2.jpg',
  '491a0c_915a1f5f649d4829a450375c11c55165~mv2.jpg',
  '491a0c_ba6911c295144bef9ad620683351f281~mv2.jpg',
  '491a0c_c998d677f0f64a048e4f5364092bc7d2~mv2.jpg',
  '491a0c_f6ea740311d840bf9a6157f663571066~mv2.jpg',
  '491a0c_f772af17e6e3460697f2db860355a24c~mv2.jpg',
  '491a0c_1309ea1f4b994b4a81db2b8b5ee8152b~mv2.jpg',
  '491a0c_19d439a8ffb54136a08a27bb6ad20d90~mv2.jpg',
  '491a0c_2d54d02a5155414e9b8efb0cd9c22132~mv2.jpg',
  '491a0c_4f27032f6bb94988981f07034445e4c3~mv2.jpg',
  '491a0c_56385870dbfe4595ab6c2286792879c3~mv2.jpg',
  '491a0c_616e3be0d6194612950cd5d5e051b874~mv2.jpg',
];

export const aDemoProduct = (i: number = 0): IProduct => {
  return aProductBuilder()
    .withId(generateGuid())
    .withName(`Demo Product`)
    .withUrlPart(`product-url-part-${i}`)
    .withPrice(7.5)
    .withComparePrice(0)
    .withFormattedPrice('$7.50')
    .withFormattedComparePrice('$0.0')
    .withInventory({status: 'in_stock'})
    .withRibbon('')
    .withIsInStock(true)
    .withProductType('physical')
    .withDigitalProductFileItems([])
    .withOptions([])
    .withHasOptions(false)
    .withMedia([
      aProductMediaItemBuilder()
        .withAltText(`Demo product ${i}`)
        .withHeight(1000)
        .withWidth(1000)
        .withId(null)
        .withMediaType('PHOTO')
        .withUrl(demoAssets[i % demoAssets.length])
        .get(),
    ])
    .get() as IProduct;
};
